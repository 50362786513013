<template>
    <div >
        <b-card 
        class=""
        bg-variant="light-primary"
        style="margin: 0 auto; text-align: center;">
            <table 
              style="width: -webkit-fill-available; font-size: large;">
              <br>
              <tr>
                <th 
                colspan="12" 
                style="color:black; font-size: x-large;"> 
                    자동 예약 {{ pageType }}
                </th>
              </tr>
              <tr>
                <td colspan="12">
                    <div class="divider">
                        <div class="divider-text text-primary">
                            요일
                        </div>
                    </div>
                </td>
              </tr>
              <tr>
                <td
                    v-for="day of dayOptions"
                    :key="day.value"
                    @click="pressDayBtn(day.value)"
                >
                    <b-badge
                        :style="`font-size:x-large;border: ${(!existDay(day.value))? '2.5px outset': '2.5px inset'}`"
                        :variant="selectedColor(existDay(day.value))"
                    >{{ day.text }}</b-badge>
                </td>
              </tr>
              <tr>
                <td colspan="12">
                    <div class="divider">
                        <div class="divider-text text-primary">
                            조건
                        </div>
                    </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                    <b-form-select 
                        style="border: 1.5px outset; font-weight: bold; font-size: large; text-align: center; color: black"  
                        :options="autoMode" 
                        v-model="selectedMode">
                    </b-form-select>
                </td>
                <td colspan="4">
                    <b-form-select 
                        @change="showBtnsList(selectedBtnType)"
                        style="border: 1.5px outset; font-weight: bold; font-size: large; text-align: center; color: black" 
                        :options="buttonTypeOptions"
                        text-field="label" 
                        :disabled="selectBtnTypeInActive"
                        v-model="selectedBtnType">
                    </b-form-select>
                </td>
              </tr>
            </table>
            <div v-if="selectedMode === 'time'">
                <div class="divider">
                    <div class="divider-text text-primary">
                        시간
                    </div>
                </div>
                <vue-timepicker 
                    class="clockCss"
                    v-model="selectedTime"
                    close-on-complete
                    apm-label="오전/오후" 
                    hour-label="시" 
                    minute-label="분" 
                    pm-text="오후" 
                    am-text="오전" 
                    format="HH시 mm분" 
                    input-width="100%"
                    placeholder="시간을 선택해주세요.">
                </vue-timepicker>
            </div>
            <div v-if="selectedMode === 'sensor'">
                <div class="divider">
                    <div class="divider-text text-primary">
                        센서
                    </div>
                </div>
                <b-form-group class="text-center">
                    <b-form-select
                        placeholder="센서를 선택해주세요"
                        :options="sensorList"
                        v-model="selectedSensor"
                        size="lg"
                        text-field="label"
                    >
                    </b-form-select>
                    <span slot="no-options">등록된 센서가 없습니다.</span>
                </b-form-group>
            </div>
            <div class="divider">
                <div class="divider-text text-primary">
                    장비
                </div>
            </div>
            <div v-if="selectedBtnType !== ''">
                <b-form-tags
                    disabled
                    style="font-size: large"
                    v-model="selectedBtnsNameList"
                    placeholder="장비 선택 목록"
                ></b-form-tags>
                <b-form-select
                    placeholder="장비를 선택해주세요"
                    :options="btnsOptions"
                    v-model="selectedBtns"
                    multiple
                    :disabled="selectBtnTypeInActive"
                    size="lg"
                >
                </b-form-select>
            </div>
            <div v-else ><p class="dontChoose">장비 종류를 선택해주세요</p></div>
            <div class="divider">
                <div class="divider-text text-primary">
                    동작
                </div>
            </div>
            <div v-if="selectedMode === 'sensor'">
                <table style="table-layout: fixed; width: -webkit-fill-available; font-size: large; margin: 2rem;">
                    <tr>
                        <th width="25%">
                            동작 조건 
                        </th>
                        <td width="25%" >
                            <b-input-group
                            append="℃ 이상"
                            >
                                <b-form-input
                                    v-model="maxValue"
                                    lazy-formatter
                                    :formatter="formatter"
                                    type="number"
                                    style="width: 20%; color:tomato; font-weight: bold;"
                                />
                            </b-input-group>
                        </td>
                        
                    </tr>
                    <tr>
                        <th width="25%">
                            정지 조건 
                        </th>
                        <td width="25%">
                            <b-input-group
                            append="℃ 이하"
                            >
                                <b-form-input
                                :disabled="maxValue <= 0"
                                    v-model="minValue"
                                    lazy-formatter
                                    :formatter="formatter"
                                    type="number"
                                    style="width: 20%; color:royalblue; font-weight: bold"
                                />
                            </b-input-group>
                        </td>
                    </tr>
                    <tr v-if="maxValue <= minValue">
                        <td colspan="10" class="dontChoose">
                            정지조건의 온도는 동작 조건의 온도보다 낮아야 합니다.
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="selectedBtnsNameList.length > 0 ">
                <b-form-group
                v-if="openStopCloseBtnList.includes(selectedBtnType)"
                >
                    <b-form-radio-group
                    v-model="selectedCommand"
                    button-variant="outline-primary"
                    :options="openStopCloseBtn"
                    buttons
                    class="d-flex"
                    />
                </b-form-group>
                <b-form-group
                    v-else-if="workStopBtnList.includes(selectedBtnType) && selectedMode!=='sensor'"
                >
                    <!-- <vue-slider
                    v-if="(selectedBtnType ==='inverter' || selectedBtnType === 'inverter_hd') && selectedBtnType !=='sensor'"
                    v-model="sliderValue"
                    class="p-1 m-2 text-primary"
                    tooltip="always"
                    :min="6"
                    :max="60"
                    :interval="6"
                    :tooltip-formatter="`${sliderValue/60*100}%`"
                    /> -->
                    <b-form-radio-group
                    v-model="selectedCommand"
                    button-variant="outline-primary"
                    :options="workStopBtn"
                    buttons
                    class="d-flex"
                    />
                </b-form-group>
                <b-form-group
                    v-else-if="workBtnList.includes(selectedBtnType)"
                >
                    <b-form-radio-group
                    v-model="selectedCommand"
                    button-variant="outline-primary"
                    :options="workBtn"
                    buttons
                    class="d-flex"
                    />
                </b-form-group>
            </div>
            <div v-else>
                <p class="dontChoose">
                    장비 목록에서 선택해주세요
                </p>
            </div>
            <div class="divider">
                <div class="divider-text text-primary">
                    -- 설정 --
                </div>
            </div>
            <div v-if="saveBtnActive">
                <b-button
                type="submit"
                variant="primary"
                class="float-right mt-1 mb-1"
                size="lg"
                block
                @click="submit"
                >
                확인
                </b-button>
            </div>
            <div v-else>
                <p class="dontChoose">
                    모든 옵션을 선택해주세요.
                </p>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BFormTags,
    BFormTag,
    BBadge, 
    BRow, 
    BCol, 
    BFormGroup, 
    BButton, 
    BFormCheckboxGroup, 
    BCard, 
    BCardTitle, 
    BFormTimepicker, 
    BFormSpinbutton, 
    BFormInput, 
    BFormRadioGroup, 
    BInputGroup,
    BFormSelect,
    
} from 'bootstrap-vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import store from '@/store'
import { getUserData } from '@/auth/utils'

export default {
    components: {
        BFormTags,
        BFormTag,
        BBadge,
        BRow,
        BCol,
        BCard,
        BCardTitle,
        BFormGroup,
        BButton,
        BFormCheckboxGroup,
        BFormTimepicker,
        BFormSpinbutton,
        BFormInput,
        BFormRadioGroup,
        BInputGroup,
        BFormSelect,
        VueTimepicker
    },
    data () {
        return {
            saveBtnActive: false,
            pageType: (this.$route.params.buttonId && this.$route.params.autoId) ? '수정' : '등록',
            dayOptions: [
                { text: '일', value: 6 },
                { text: '월', value: 0 },
                { text: '화', value: 1 },
                { text: '수', value: 2 },
                { text: '목', value: 3 },
                { text: '금', value: 4 },
                { text: '토', value: 5 },
            ],
            selectedDay: [0, 1, 2, 3, 4, 5, 6],
            autoMode: [
                /* { text: '자동화 선택', value: null }, */
                { text: '시간 모드', value: 'time' },
                { text: '센서 모드', value: 'sensor', disabled: true }
            ],
            selectedMode: 'time',
            buttonTypeOptions: [],
            selectBtnTypeInActive: false,
            selectedBtnType: '',
            selectedHour: '',
            selectedMinute: '',
            selectedTime: null,
            btnsOptions: [],
            selectedBtns: [],
            selectedBtnsNameList: [],
            btnCommand: [],
            selectedCommand: '',
            selectedSensor:'',
            sensorList: [],
            openStopCloseBtnList: [
                'curtain', 
                'ceiling', 
                'feeder_necklace', 
                'ceiling_reverse', 
                'hydraulic_trigger'
            ],
            workStopBtnList: [
                'inverter', 'inverter_hd', 'inverter_hz',
                'light', 'fan',   
                'ceiling_2button', 
                'feeder', 
                'hydraulic_trigger2',
                'hydraulic_complex',
                'sprayer', 'sprayer_trigger' //'curtain_toggle', 'feeder_1button',
            ],
            workBtnList: [
                'fan_trigger', 
                'feeder_1button'
            ],
            openStopCloseBtn: [
                { text: '열림', value: 'open' },
                { text: '정지', value: 'stop' },
                { text: '닫힘', value: 'close' },
            ],
            workStopBtn: [
                { text: '동작', value: 'work' },
                { text: '정지', value: 'stop' },
            ],
            workBtn: [
                { text: '전원 on/off', value: 'work'},
            ],

            // Sensor Automation
            minValue: 0, //정지 온도
            maxValue: 0, //동작 온도
            minStep: 6,
            maxStep: 60,
            autoIndex: 0,
            autoId: null
        }
    },
    computed: {
        availableOptions() {
            return this.btnsOptions.filter(el => this.selectedBtns.indexOf(el.value) === -1)
        },
    },
    watch: {
        maxValue() {
            this.maxValue = parseInt(this.maxValue)
        },
        minValue() {
            this.minValue = parseInt(this.minValue)
            if(this.maxValue > this.minValue) this.saveBtnActive = true
        },
        selectedBtns() {
            let tempArray = []
            for(let selected of this.selectedBtns) tempArray.push(this.btnsOptions.find(e => e.value === selected).text)
            this.selectedBtnsNameList = tempArray
        },
        selectedBtnType() {
            if(this.selectedBtnType === 'fan') this.autoMode[1].disabled = false
            else {
                this.autoMode[1].disabled = true
                this.selectedMode = 'time'
            }
        },
        async selectedMode(){
            if(this.selectedMode === 'sensor') {
                this.saveBtnActive = false
                if (store.state.sensor.sensors.length <= 0) {
                    await store.dispatch('sensor/fetchSensors',
                    { userId: getUserData().id })
                }
                this.sensorList = await store.getters['sensor/getSensorSelect'].filter(sensor => sensor.label.indexOf('온도') > -1 || sensor.label.indexOf('Temp') > -1)
                if(this.sensorList.length <= 0) {
                    this.sensorList.unshift({label: '등록된 센서가 존재하지 않습니다.', value: null, disabled: true})
                }
                this.selectedSensor = this.sensorList[0].value
            } else this.saveBtnActive = false
        },
        selectedCommand() {
            if(this.selectedMode === 'time') {
                if(this.selectedBtnsNameList.length > 0
                && this.selectedDay.length > 0
                && this.selectedCommand.length > 0) {
                    this.saveBtnActive = true
                }
                
            }
        }
        /* pressDayBtn(dayNum) {
            console.log('dayNum : ', dayNum, this.existDay(dayNum))
            if(this.existDay(dayNum)) this.selectedDay.splice(this.selectedDay.findIndex(e => e === dayNum), 1) 
            else this.selectedDay.push(dayNum)
        }, */
    },
    created() {
        
        
        this.getButtonCheckbox()
        if(this.$route.params.buttonId) {
            this.selectBtnTypeInActive = true
            this.editData()
        } else {
            this.selectBtnTypeInActive = false
            this.defaultTimeSet()
        }
    },
    methods: {
        async editData() {
            const autoBtn = await store.dispatch('button/fetchButton', {id: this.$route.params.buttonId})
            let setting = autoBtn.data.autoSettings
            this.autoId = this.$route.params.autoId
            this.autoIndex = setting.findIndex(el => el._id === this.autoId)
            
            const targetAuto = setting.find(e => e._id === this.$route.params.autoId)
            let tempTime = targetAuto.startTime.split(':')
            this.selectedDay = targetAuto.days
            this.selectedMode = targetAuto.mode
            this.selectedTime = `${tempTime[0]}시 ${tempTime[1]}분`
            this.selectedCommand = targetAuto.command
            this.selectedBtnType = this.buttonTypeOptions.find(e => e.value === autoBtn.data.type).value
            await this.showBtnsList(this.selectedBtnType)
            this.selectedBtns.push(this.btnsOptions.find(el => el.value === this.$route.params.buttonId).value)
            this.saveBtnActive = true
        },
        formatter(value) {
            if(value > 100) return 99
            return parseInt(value)
        },
        getSensorParam() {
            return {
                mode: this.selectedMode,
                days: this.selectedDay,
                command: this.selectedCommand,
                commandValue: null,
                sensorId: this.selectedSensor,
                minValue: this.minValue,
                maxValue: this.maxValue,
            }
        },
        submit() {
            let time = this.selectedTime.split(' ')
            
            const settingParam = (this.selectedMode === 'sensor')? this.getSensorParam():{
                mode: this.selectedMode,
                days: this.selectedDay,
                command: this.selectedCommand,
                commandValue: null,
                startTime: `${time[0].substring(0,2)}:${time[1].substring(0,2)}`,
            }
            

            const param = (this.$route.params.buttonId) 
            ? {
                id: this.selectedBtns,
                setting: settingParam,
                autoId: this.autoId,
                index: this.autoIndex,
            }
            : {
                ids: this.selectedBtns,
                setting: settingParam,
            }

            const contentOfDay = []
            for(let day of this.selectedDay) {
                contentOfDay.push(this.dayOptions.find(e=>e.value === day).text)
            }

            const element = this.$createElement
            const contents = element('b', {
                domProps: 
                    { innerHTML: `
                    선택 장비: ${this.selectedBtnsNameList} <br>
                    선택 요일: ${contentOfDay} <br>
                    선택 시간: ${this.selectedTime} <br>
                    선택 동작: ${this.selectedCommand} <br>
                    
                    `} 
                }
            )
            

            this.$bvModal.msgBoxConfirm([contents], {
                title: `자동화 ${(this.$route.params.buttonId)? '수정': '등록'} 확인`,
                titleTag: 'h4',
                okTitle: '저장하기',
                okVariant: 'primary',
                cancelTitle: '돌아가기',
                hideHeaderClose: true,
                centered: true,
            }).then((ok) => {
                if(ok) {
                    if (this.$route.params.buttonId) {
                        store.dispatch('button/updateSetting', { id: this.$route.params.buttonId, queryBody: param }).then(() => {
                            this.$emit('submit')
                            this.$emit('update')
                            this.$router.push({ name: 'remote-control' })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    } else {
                        store.dispatch('button/addSettingMany', { queryBody: param }).then(async () => {
                            this.$emit('submit')
                            await store.dispatch('button/fetchButtons', { userId: getUserData().id })
                            this.$emit('update')
                            this.$router.push({ name: 'remote-control' })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    }
                    
                }
            })

            
        },
        selectBtnType(btnType) {

            /* let temp = this.openStopCloseBtnList.includes(btnType)
            this.btnCommand = openStopCloseBtn
            console.log('Result :: ', temp, this.btnCommand) */
        },
        selectAllBtn() {
            for(let btn of this.btnsOptions) this.selectedBtns.push(btn.value)
            this.selectedBtns = this.selectedBtns.filter(e => e !== 'all')
        },
        getBtnName(tagId) {
            return this.btnsOptions.find(e => e.value === tagId).text
        },
        defaultTimeSet() {
            let time = new Date()
            let hours = time.getHours()
            if(hours.length === 1) hours = `0${hours}`
            this.selectedHour = hours
            let minutes = time.getMinutes()
            if(minutes.length === 1) minutes = `0${minutes}`
            this.selectedMinute = minutes
            this.selectedTime = `${hours}시 ${minutes}분`
        },
        pressDayBtn(dayNum) {
            if(this.existDay(dayNum)) this.selectedDay.splice(this.selectedDay.findIndex(e => e === dayNum), 1) 
            else this.selectedDay.push(dayNum)
        },
        existDay (dayNum) {
            return this.selectedDay.findIndex(e => e === dayNum) > -1
        },
        selectedColor(existDay) {
            return (existDay)? 'primary': 'secondary'
        },
        async showBtnsList(btnType) {
            this.btnsOptions = await store.getters['button/getButtonInTypeOptions'](btnType)
            //this.selectBtnType(this.selectedBtnType)
            //this.selectedBtns.push(this.btnsOptions[0].value)
        },
        
        async getButtonCheckbox() {
            if (store.state.button.buttons.length === 0) {
                await store.dispatch('button/fetchButtons', { userId: getUserData().id })
            }
            // allow button
            let allowButton = [
                'curtain', 
                'ceiling', 'ceiling_2button', 'ceiling_reverse', 
                'light', 
                'fan', 'fan_trigger', 
                'inverter', 'inverter_hd', 'inverter_hz', 
                'feeder', 'feeder_1button', 'feeder_necklace',
                'hydraulic_complex', 'hydraulic_trigger', 'hydraulic_trigger2',
                'sprayer', 'sprayer_trigger']

            this.buttonTypeOptions = await store.getters['button/getButtonTypes']('').filter(button => allowButton.indexOf(button.value) > -1)
            
            let hydraulic_complex = await store.getters['button/getButtonInType']('hydraulic_complex')
            for(let btn of hydraulic_complex) {
                if(this.buttonTypeOptions.findIndex(e => e.value === btn.type) <= -1) {
                    this.buttonTypeOptions.push({
                        label: '유압제어',
                        value: btn.type
                    })
                }
            }
            let hydraulic_trigger = await store.getters['button/getButtonInType']('hydraulic_trigger')
            for(let btn of hydraulic_trigger) {
                if(this.buttonTypeOptions.findIndex(e => e.value === btn.type) <= -1) {
                    this.buttonTypeOptions.push({
                        label: '유압 트리거',
                        value: btn.type
                    })
                }
            }
            let hydraulic_trigger2 = await store.getters['button/getButtonInType']('hydraulic_trigger2')
            for(let btn of hydraulic_trigger2) {
                if(this.buttonTypeOptions.findIndex(e => e.value === btn.type) <= -1) {
                    this.buttonTypeOptions.push({
                        label: '유압 트리거2',
                        value: btn.type
                    })
                }
            }
            let hydraulic_1trigger = await store.getters['button/getButtonInType']('hydraulic_1trigger')
            for(let btn of hydraulic_1trigger) {
                if(this.buttonTypeOptions.findIndex(e => e.value === btn.type) <= -1) {
                    this.buttonTypeOptions.push({
                        label: '유압 트리거1',
                        value: btn.type
                    })
                }
            }
            let feeder_necklace = await store.getters['button/getButtonInType']('feeder_necklace')
            for(let btn of feeder_necklace) {
                if(this.buttonTypeOptions.findIndex(e => e.value === btn.type) <= -1) {
                    this.buttonTypeOptions.push({
                        label: '자동 목걸이',
                        value: btn.type
                    })
                }
            }

            
            /* if (hydraulic2Items.length >= 1) {
                for(let hy of hydraulic2Items) {
                    if(this.buttonTypeOptions.findIndex(e => e.value === hy.type)) {
                        console.log('중복!', {
                        label: hy.name,
                        value: hy.type
                    })
                    }
                    this.buttonTypeOptions.push({
                        label: hy.name,
                        value: hy.type
                    })
                }
            } */

            let inverter_hd = this.buttonTypeOptions.filter(button => button.value === 'inverter_hd')
            let inverter_ls = this.buttonTypeOptions.filter(button => button.value === 'inverter')
            if (inverter_hd.length && inverter_ls.length) {
                this.buttonTypeOptions = this.buttonTypeOptions.filter(button => button.value !== 'inverter_hd')
            }
            this.selectedBtnType = this.buttonTypeOptions[0].value
            this.showBtnsList(this.selectedBtnType)
        },
    },
}

</script>

<style scoped>
.clockCss {
    text-align: center;
    font-size: 1.4rem;
}

.dontChoose {
    color: red;
    font-size: x-large;
}
</style>